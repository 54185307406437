import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import * as Sentry from '@sentry/react';
import { RetryLink } from 'apollo-link-retry';
import { persistCache } from 'apollo-cache-persist';
import QueueLink from 'apollo-link-queue';
import SerializingLink from 'apollo-link-serialize';
import { Auth } from 'aws-amplify';
import trackerLink from './trackerLink';
import store from '../persistMutations/store';

const customFetch = async (uri, options) => {
  try {
    const session = await Auth.currentSession();
    const idToken = session?.idToken?.jwtToken;
    if (idToken) options.headers.Authorization = idToken;
  } catch (e) {
    Sentry.captureException(e);
    console.error('Authentication Error.'); // eslint-disable-line no-console
  }

  return fetch(uri, options);
};

const retry = new RetryLink({ attempts: { max: Infinity } });
const http = (uri) => new HttpLink({ uri, fetch: customFetch });

export const cache = new InMemoryCache();
const storage = window.localStorage;
export const waitOnCache = persistCache({ cache, storage });

const serializingLink = new SerializingLink();
export const queueLink = new QueueLink();

const getClient = (uri) =>
  new ApolloClient({
    cache,
    link: ApolloLink.from([
      trackerLink(store.dispatch),
      queueLink,
      serializingLink,
      retry,
      http(uri),
    ]),
  });

export const ReactPwaTemplateClient = getClient(
  //TODO chance client and .env here
  process.env.REACT_APP_YOUR_GRAPHQL_URI
);
