import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { useAuthUser } from '@hala-systems/hala-auth';

export const Unauthorized = () => {
  const { user, loading } = useAuthUser();

  if (loading) return 'Logging you in...';

  const content = user ? <NotAuthorizedMessage /> : <NotLoggedInMessage />;
  return <Box p={6}>{content}</Box>;
};

const NotLoggedInMessage = () => (
  <>
    <Typography variant="h4" component="h2" gutterBottom>
      Access Denied
    </Typography>
    <Button onClick={() => Auth.federatedSignIn()}>Sign In</Button>
  </>
);

const NotAuthorizedMessage = () => (
  <>
    <Typography variant="body1" gutterBottom>
      Sorry, you don't currently have access to this page.
    </Typography>
    <Typography variant="body1" gutterBottom>
      Please reach out to{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="mailto:nick@halasystems.com?subject=Access to Template app App&body=Please give this email access to Template app"
      >
        nick@halasystems.com
      </a>{' '}
      to get access
    </Typography>
  </>
);
