import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import { useSetLocale } from 'locale/LocaleProvider';
import { useAuthUser } from '@hala-systems/hala-auth';
import {
  MenuVerticalIcon,
  BritishFlag,
  YemenFlag,
  WifiWarning,
  Clock,
} from 'styles/assets/icons';
import { useStyles, MenuToolbar, Heading } from './NavBarStyles';
import { Auth } from 'aws-amplify';
import { userHasGroup } from 'auth/permissions';

export const NavBar = () => {
  const [isOnline, setNetwork] = useState(window.navigator.onLine);
  const updateNetwork = () => {
    setNetwork(window.navigator.onLine);
  };
  useEffect(() => {
    window.addEventListener('offline', updateNetwork);
    window.addEventListener('online', updateNetwork);
    return () => {
      window.removeEventListener('offline', updateNetwork);
      window.removeEventListener('online', updateNetwork);
    };
  });

  const { user, loading } = useAuthUser();

  const setLocale = useSetLocale();
  const { locale } = useIntl();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const logout = (event) => {
    Auth.signOut();
    setAnchorEl(null);
  };
  // TODO add back in login/logout things once we move away from cognito
  const myRef = React.createRef();
  return (
    <Box boxShadow={3}>
      <MenuToolbar variant="dense">
        <Link to="/">
          <Clock />
        </Link>
        <Link to="/">{!isOnline && <WifiWarning />}</Link>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Heading variant="h6">
            <FormattedMessage id="title" defaultMessage="My app name" />
          </Heading>
          <Select
            name="language"
            value={locale}
            IconComponent={ArrowDropDownIcon}
            onChange={(value) => {
              setLocale(value.target.value);
            }}
            id="language"
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            <MenuItem key="en" value="en" id="en" name="en">
              <BritishFlag />
            </MenuItem>
            <MenuItem key="ar" value="ar" id="ar" name="ar">
              <YemenFlag />
            </MenuItem>
          </Select>
          <IconButton
            edge="end"
            aria-controls="user-menu"
            aria-label="open user-menu"
            aria-haspopup="true"
            onClick={handleClick}
            className={classes.hamburger}
          >
            <MenuVerticalIcon />
          </IconButton>
          <Menu
            id="user-menu"
            getContentAnchorEl={null}
            anchorEl={anchorEl}
            ref={myRef}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            {userHasGroup(user, 'ApprovedUser') && (
              <MenuItem component={Link} to="/" onClick={handleClose}>
                Home
              </MenuItem>
            )}
            {userHasGroup(user, 'ApprovedUser') && (
              <MenuItem
                component={Link}
                to="/incidents-list"
                onClick={handleClose}
              >
                Incident List
              </MenuItem>
            )}
            {userHasGroup(user, 'ApprovedUser') && (
              <MenuItem
                component={Link}
                to="/submit-incident"
                onClick={handleClose}
              >
                Submit Incident
              </MenuItem>
            )}
            <MenuItem
              component="a"
              href="mailto:nick@halasystems.com" // TODO update the email to send
              target="_blank"
              onClick={handleClose}
            >
              <FormattedMessage id="feedback" defaultMessage="Send Feedback" />
            </MenuItem>
            {!user & !loading ? (
              <MenuItem onClick={() => Auth.federatedSignIn()}>
                <FormattedMessage id="signIn" defaultMessage="Sign In" />
              </MenuItem>
            ) : (
              <MenuItem onClick={logout}>
                <FormattedMessage id="logout" defaultMessage="Logout" />
              </MenuItem>
            )}
          </Menu>
        </Grid>
      </MenuToolbar>
    </Box>
  );
};
