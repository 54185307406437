import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Toolbar, Typography } from '@material-ui/core';

export const Heading = withStyles((theme) => ({
  root: {
    fontWeight: 600,
    color: 'white',
    letterSpacing: '0.15px',
    // marginRight: '10px',
  },
}))(Typography);

export const MenuToolbar = withStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#1DA1F2',
    alignItems: 'center',
    flexDirection: 'row',
    overflow: 'visible',
    zIndex: 8,
    height: '48px',
  },
}))(Toolbar);

export const useStyles = makeStyles((theme) => ({
  hamburger: {
    outline: 'none !important',
  },

  icon: {
    fill: 'white',
    marginRight: '0px',
  },
}));
