import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { getOnline, setOnline } from './persistMutations/store/online';
import {
  getTrackedMutations,
  trackedMutationsRemove,
} from './persistMutations/store/trackedMutations';
import Routes from './Routes';
import { queueLink } from './services/apollo';
import { updateHandlerByName } from './persistMutations/updateHandler';

const App = () => {
  const apolloClient = useApolloClient();
  const dispatch = useDispatch();
  const online = useSelector(getOnline);
  const trackedMutations = useSelector(getTrackedMutations);

  useEffect(() => {
    const execute = async () => {
      trackedMutations.forEach((trackedMutation) => {
        const context = JSON.parse(trackedMutation.contextJSON);
        const query = JSON.parse(trackedMutation.queryJSON);
        const variables = JSON.parse(trackedMutation.variablesJSON);

        apolloClient.mutate({
          context,
          mutation: query,
          optimisticResponse: context.optimisticResponse,
          update: updateHandlerByName[trackedMutation.name],
          variables,
        });

        dispatch(trackedMutationsRemove(trackedMutation.id));
      });
    };
    execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Listen to offline changes
  useEffect(() => {
    const checkOnline = (navigator) => {
      if (navigator.onLine) {
        dispatch(setOnline(true));
      }
    };

    window.addEventListener('load', () => {
      checkOnline(navigator);
    });
    window.addEventListener('offline', () => dispatch(setOnline(false)));
    window.addEventListener('online', () => dispatch(setOnline(true)));

    return () => {
      window.removeEventListener('offline', () => dispatch(setOnline(false)));
      window.removeEventListener('online', () => dispatch(setOnline(true)));
      window.removeEventListener('load', () => {
        checkOnline(navigator);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // APOLLO CLIENT QUEUE
  useEffect(() => {
    if (online) {
      queueLink.open();
    } else {
      queueLink.close();
    }
  }, [online]);

  return (
    <>
      <Routes />
    </>
  );
};

export default App;
