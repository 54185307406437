import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const WifiWarning = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 25">
      <path
        d="M5.95996 11.484C7.73249 9.71488 10.5078 9.44401 12.589 10.837"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.07007 8.02201C6.78848 4.6039 12.5016 4.59131 16.2351 7.99301"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.75 4.08501C5.9649 -0.100374 13.3884 -0.0974629 18.6 4.09201"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 20.45C17.4571 20.45 17.625 20.6179 17.625 20.825C17.625 21.0321 17.4571 21.2 17.25 21.2C17.0429 21.2 16.875 21.0321 16.875 20.825C16.875 20.6179 17.0429 20.45 17.25 20.45"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 18.2V15.2"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0631 20.883C23.3379 21.4329 23.3084 22.086 22.9851 22.6089C22.6619 23.1318 22.0909 23.4501 21.4761 23.45H13.0241C12.4093 23.4501 11.8384 23.1318 11.5151 22.6089C11.1918 22.086 11.1623 21.4329 11.4371 20.883L15.6631 12.431C15.9635 11.8297 16.5779 11.4498 17.2501 11.4498C17.9223 11.4498 18.5367 11.8297 18.8371 12.431L23.0631 20.883Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
