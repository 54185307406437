import React from 'react';
import ReactDOM from 'react-dom';
import 'toastr/build/toastr.min.css';

// import * as Sentry from '@sentry/react'; TODO Add back in Sentry once setup
// import { Integrations } from '@sentry/tracing';

import 'index.css';
import App from 'App';
// import * as serviceWorker from 'serviceWorker';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { initAmplify } from 'services/amplify';
import { waitOnCache } from './services/apollo';

// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_DSN,
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE ?? 1,
// });

// TODO put this in if you are using Amplify
initAmplify();

// wait for the cache to sync before starting the app

waitOnCache.then(() => {
  ReactDOM.render(<App />, document.getElementById('root'));
});

serviceWorkerRegistration.register();
