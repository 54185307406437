import { ApolloLink } from 'apollo-link';
import stringify from 'json-stringify-safe';
import { v4 as uuidv4 } from 'uuid';
import {
  trackedMutationsAdd,
  trackedMutationsRemove,
} from '../persistMutations/store/trackedMutations';

const trackerLink = (dispatch) =>
  new ApolloLink((operation, forward) => {
    if (forward === undefined) {
      return null;
    }
    const name = operation.operationName;

    const queryJSON = JSON.stringify(operation.query);

    const variablesJSON = JSON.stringify(operation.variables);

    const context = operation.getContext();

    const contextJSON = stringify(context);

    const id = uuidv4();

    if (context.tracked !== undefined) {
      dispatch(
        trackedMutationsAdd({
          contextJSON,
          id,
          name,
          queryJSON,
          variablesJSON,
        })
      );
    }

    return forward(operation).map((data) => {
      if (context.tracked !== undefined) {
        dispatch(trackedMutationsRemove(id));
      }
      return data;
    });
  });

export default trackerLink;
