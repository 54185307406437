import { Auth } from 'aws-amplify';

// export const IDENTITY_POOL = process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID;
export const REGION = process.env.REACT_APP_AWS_PROJECT_REGION;
const USER_POOL_ID = process.env.REACT_APP_AWS_USER_POOL_ID;
const CLIENT_ID = process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID;
const REDIRECT_SIGNIN = process.env.REACT_APP_LOGIN_CALLBACK_URL;
const REDIRECT_SIGNOUT = process.env.REACT_APP_LOGOUT_CALLBACK_URL;

const amplifyConfig = {
  Auth: {
    // identityPoolId: IDENTITY_POOL,
    region: REGION,
    userPoolId: USER_POOL_ID,
    userPoolWebClientId: CLIENT_ID,
    oauth: {
      domain: 'react-pwa-template-dev.auth.us-east-1.amazoncognito.com/', // TODO change domain
      scope: [
        'aws.cognito.signin.user.admin',
        'email',
        'openid',
        'phone',
        'profile',
      ],
      redirectSignIn: REDIRECT_SIGNIN,
      redirectSignOut: REDIRECT_SIGNOUT,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
};
export const initAmplify = () => {
  Auth.configure(amplifyConfig);
};
