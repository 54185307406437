/* eslint-disable no-console */
import React from 'react';
import { NetworkStatus, useQuery } from '@apollo/client';
import { ListIncidents } from 'components/pages/ListIncidents';
import { LinearProgress, Typography } from '@material-ui/core';
import GET_INCIDENTS from 'queries/getIncidents';
import { FormattedMessage } from 'react-intl';

export const ListIncidentsContainer = () => {
  const { data, error, refetch, networkStatus } = useQuery(GET_INCIDENTS, {
    notifyOnNetworkStatusChange: true,
  });
  const loading = networkStatus === NetworkStatus.loading;
  const refetching =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables;

  if (error)
    return (
      <>
        <FormattedMessage
          id="pageError"
          defaultMessage="Error loading page. Please refresh and try again"
        />
      </>
    );
  if (loading)
    return (
      <>
        <Typography variant="h5">
          <FormattedMessage
            id="loadingIncidents"
            defaultMessage="Loading Incident...."
          />
        </Typography>
        <LinearProgress variant="query" />
      </>
    );
  const incidents = data?.getIncidents?.result ?? null;
  return (
    <ListIncidents
      incidents={incidents}
      refetch={refetch}
      refetching={refetching}
    />
  );
};
