import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { regular, mono } from 'styles/fonts';
import DownCaret from 'styles/assets/icons/DownCaret';
import { breakpointValues } from 'styles/breakpoints';

const theme = createMuiTheme(
  {
    palette: {
      // todo - we should manage colors here. Use './colors'
      primary: {
        main: '#000000',
      },
      secondary: {
        main: '#FBEE33',
      },
    },
    typography: {
      fontFamily: `${regular}, ${mono}`,
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [regular, mono],
        },
      },
      MuiAutocomplete: {
        input: {
          marginTop: '-1px',
          paddingLeft: '.5em !important',
          paddingTop: '.5rem !important',
          paddingBottom: '.5rem !important',
        },
        endAdornment: {
          top: 'calc(50% - 1rem)',
          marginRight: '1rem',
        },
      },
      MuiSelect: {
        select: {
          padding: '.5em',
        },
        icon: {
          top: 'calc(50% - 1rem + 2px)',
          marginRight: '1rem',
        },
      },
      MuiFormControl: {
        root: {
          paddingBottom: '1rem',
        },
      },
      MuiInputLabel: {
        root: {
          paddingLeft: '.5em',
        },
        shrink: {
          paddingLeft: 'calc(.5em + 4px) !important',
        },
      },
      MuiInput: {
        input: {
          paddingLeft: '.5em',
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: '#FFFFFF',
          boxShadow:
            '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
          color: '#000000DE',
          fontSize: '13px',
          lineHeight: 1.2,
          padding: '10px',
        },
        arrow: {
          color: '#FFFFFF',
        },
      },
    },
    props: {
      MuiSelect: {
        IconComponent: DownCaret,
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          getContentAnchorEl: null,
        },
      },
      MuiAutocomplete: {
        popupIcon: <DownCaret />,
      },
    },
  },
  breakpointValues
);

export default theme;
