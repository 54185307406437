import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const Clock = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 25">
      <path
        fill="none"
        clipRule="evenodd"
        d="M12 22.7c5.799 0 10.5-4.701 10.5-10.5S17.799 1.7 12 1.7 1.5 6.4 1.5 12.2 6.201 22.7 12 22.7z"
        stroke="#000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="none"
        d="M12 12.2V8.45M12 12.2l4.687 4.688"
        stroke="#000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
