import React from 'react';
import { Button, Typography } from '@material-ui/core';

export const PwaFunctionality = () => {
  // TODO check if device is cell phone or laptop
  const openCamera = () => {
    console.log('open camera');
  };

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        console.log('position', position);
      },
      (error) => {
        console.log('error: ', error);
      }
    );
  };
  const getVideo = () => {
    navigator.mediaDevices.getUserMedia({
      video: {
        minAspectRatio: 1.333,
        minFrameRate: 30,
        width: 1280,
        height: 720,
      },
    });
  };

  return (
    <>
      <Typography variant="h4" component="h2" gutterBottom>
        Test PWA stuff
      </Typography>
      <br />
      <Button onClick={() => getVideo()}>Allow camera access</Button>
      <br />
      <Button onClick={() => openCamera()}>Open Camera Role</Button>
      <br />
      <Button onClick={() => getLocation()}>Access location</Button>
    </>
  );
};
