import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function DownCaret(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 13">
      <path
        d="M23.25 1.311L12.53 12.03C12.3895 12.1707 12.1988 12.2498 12 12.2498C11.8012 12.2498 11.6105 12.1707 11.47 12.03L0.75 1.311"
        fillOpacity="0"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
