import { gql } from '@apollo/client';

const GET_INCIDENTS = gql`
  query getIncidents {
    getIncidents {
      result {
        id
        lat
        lon
        incidentDescription
      }
    }
  }
`;

export default GET_INCIDENTS;
