/* eslint-disable no-console */
import React from 'react';
import { Formik } from 'formik';
import { Box, CircularProgress, TextField } from '@material-ui/core';

import { StyledFab } from './SubmitIncidentFormStyles';
import { Save } from 'styles/assets/icons';

export const SubmitIncident = ({ handleSubmit }) => {
  return (
    <Box px={5} py={3} width={{ xs: '100vw', md: '50vw' }}>
      <Formik
        enableReinitialize
        initialValues={{
          lat: '',
          lon: '',
          incidentDescription: '',
        }}
        validate={(values) => {
          const errors = {};
          if (!values.longitude) {
            errors.longitude = 'Need longitude';
          }
          if (!values.longitude) {
            errors.latitude = 'Need latitude';
          }
          if (!values.incidentDescription) {
            errors.incidentDescription = 'Need incident description';
          }
        }}
        onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
          try {
            setSubmitting(true);
            const incident = {
              lat: values.lat,
              lon: values.lon,
              incidentDescription: values.incidentDescription,
            };
            handleSubmit(incident);
          } catch (e) {
            setSubmitting(false);
          }
          resetForm();
          setSubmitting(false);
          setFieldValue('created', '');
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          isSubmitting,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                required
                id="lat"
                aria-label="lat"
                label="Latitude"
                value={values.lat}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.lat && touched.lat}
                helperText={touched.lat && errors.lat}
              />
              <TextField
                fullWidth
                required
                id="lon"
                aria-label="lon"
                label="Longitude"
                value={values.lon}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.lon && touched.lon}
                helperText={touched.lon && errors.lon}
              />
              <TextField
                fullWidth
                required
                id="incidentDescription"
                aria-label="incidentDescription"
                label="Incident Description"
                value={values.incidentDescription}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  errors.incidentDescription && touched.incidentDescription
                }
                helperText={
                  touched.incidentDescription && errors.incidentDescription
                }
              />

              <StyledFab
                disabled={isSubmitting}
                type="button"
                id="submit-form"
                aria-label="submit-form"
                variant="round"
                onClick={handleSubmit}
              >
                {isSubmitting ? <CircularProgress /> : <Save />}
              </StyledFab>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};
