import React, { useContext } from 'react';
import { IntlProvider } from 'react-intl';
import createPersistedState from 'use-persisted-state';

import messages_en from './translations/en.json';
import messages_ar from './translations/ar.json';

// TODO change the persisted name for your local storage
const useLanguageSetPersist = createPersistedState('my-appname-languageSet');

const LanguageContext = React.createContext({});

export const LocaleProvider = ({ children }) => {
  const [locale, setLocale] = useLanguageSetPersist('en');

  const messages = {
    ar: messages_ar,
    en: messages_en,
  };

  return (
    <LanguageContext.Provider value={(locale, setLocale)}>
      <IntlProvider
        messages={messages[locale]}
        locale={locale}
        defaultLocale="en"
      >
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export const useSetLocale = () => useContext(LanguageContext);
