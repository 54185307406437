/* eslint-disable no-console */
import React from 'react';
import { useMutation } from '@apollo/client';
import { SubmitIncident } from 'components/pages/SubmitIncident';
// import GET_INCIDENTS from 'queries/getIncidents';
import SUBMIT_INCIDENT from 'mutations/submitIncident';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import Toaster from 'utils/toasterConfig';
import { updateHandlerByName } from 'persistMutations/updateHandler';

export const SubmitIncidentContainer = () => {
  const intl = useIntl();

  const [submitIncident, { error, loading }] = useMutation(SUBMIT_INCIDENT, {
    context: {
      serializationKey: 'MUTATION',
      tracked: true,
    },
    onCompleted: () => {
      if (error) {
        Toaster.error(intl.formatMessage({ id: 'toasterError' }));
        return;
      }

      Toaster.success(intl.formatMessage({ id: 'toasterSuccess' }));
      // history.push('/');
    },

    // Attempts to refetch the query
    // refetchQueries: [
    //   { query: GET_INCIDENTS },
    // ],

    // Updating cache to remove post that was just submitted
    // implemented through trackerLink
    update: updateHandlerByName.submitIncident,
  });
  const handleSubmit = (incident) => {
    submitIncident({
      variables: {
        incident,
      },
      optimisticResponse: {
        submitIncident: {
          __typename: 'IncidentResponse',
          result: [
            {
              ...incident,
              incidentDescription: `${incident.incidentDescription} (PENDING)`,
              id: uuidv4(),
              __typename: 'Incident',
            },
          ],
        },
      },
    });
  };

  return (
    <SubmitIncident
      handleSubmit={handleSubmit}
      loading={loading}
      error={error}
    />
  );
};
