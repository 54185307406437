import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ProtectedRoute } from '@hala-systems/hala-auth';

import { NavBar } from 'components/layout/nav';

// PAGES
import { Home } from 'components/pages/Home';

import { Unauthorized } from 'components/pages/Unauthorized';
import { ListIncidentsContainer } from 'containers/ListIncidents';
import { SubmitIncidentContainer } from 'containers/SubmitIncident';

import { userHasGroup } from 'auth/permissions';
import { PwaFunctionality } from 'components/pages/PwaFunctionality';

const Routes = () => {
  const hasGroupTest = (group) => (user) => userHasGroup(user, group);

  // TODO should I keep as protected route and deploy a user pool, or should I switch to Route

  return (
    <Router>
      <NavBar />
      <Switch>
        <ProtectedRoute
          exact
          path="/"
          userPassesTest={hasGroupTest('ApprovedUser')}
          deniedComponent={Unauthorized}
          component={Home}
        />
        <ProtectedRoute
          exact
          path="/submit-incident"
          userPassesTest={hasGroupTest('ApprovedUser')}
          deniedComponent={Unauthorized}
          component={SubmitIncidentContainer}
        />
        <ProtectedRoute
          exact
          path="/incidents-list"
          userPassesTest={hasGroupTest('ApprovedUser')}
          deniedComponent={Unauthorized}
          component={ListIncidentsContainer}
        />
        <ProtectedRoute
          exact
          path="/test-pwa"
          userPassesTest={hasGroupTest('ApprovedUser')}
          deniedComponent={Unauthorized}
          component={PwaFunctionality}
        />
      </Switch>
    </Router>
  );
};

export default Routes;
