/* eslint-disable no-console */
import React from 'react';
import { Link } from 'react-router-dom';

export const Home = () => {
  // TODO add translation here
  return (
    <>
      <br />
      <Link to="/incidents-list">Test Incident List</Link>
      <br />
      <br />
      <Link to="/submit-incident">Test Submitting Incident</Link>
      <br />
      <br />
      <Link to="/test-pwa">Testing PWA functionality</Link>
    </>
  );
};
