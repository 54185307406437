// Action type
const SET_ONLINE = 'SET_ONLINE';

// Action creators
export const setOnline = (state) => ({
  payload: state,
  type: SET_ONLINE,
});

const reducer = (state = false, action) => {
  switch (action.type) {
    case SET_ONLINE:
      return action.payload;
    default:
      return state;
  }
};

// Get online status
export const getOnline = (state) => state.online;

export default reducer;
