import React from 'react';
import { ApolloProvider } from '@apollo/client';
import 'App.css';
import Routes from 'PersistedMutations';
import { ReactPwaTemplateClient } from 'services/apollo'; // TODO change all ReactPwaTemplate
import { LocaleProvider } from 'locale';
import ReactPwaTemplateThemeProvider from 'styles/ThemeProvider';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './persistMutations/store';
const App = () => {
  return (
    <LocaleProvider>
      <Provider store={store}>
        <PersistGate
          loading={<p>Loading Redux Persistence</p>}
          persistor={persistor}
        >
          <ApolloProvider client={ReactPwaTemplateClient}>
            <ReactPwaTemplateThemeProvider>
              <Routes />
            </ReactPwaTemplateThemeProvider>
          </ApolloProvider>
        </PersistGate>
      </Provider>
    </LocaleProvider>
  );
};

export default App;
