import * as Sentry from '@sentry/react';

/**
 * Checks that a user is tied to a certain group
 *
 * @param {Object} user The currently logged in user
 * @param {String} groupName A group to check for
 */

export const userHasGroup = (user, groupName) => {
  if (!user) return false;
  try {
    const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
    return groups.includes(groupName);
  } catch (err) {
    Sentry.captureMessage('User group authorization failed', { user });
    return false;
  }
};
