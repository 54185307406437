import React from 'react';
import { useIntl } from 'react-intl';
import { create } from 'jss';
import rtl from 'jss-rtl';
import {
  MuiThemeProvider,
  StylesProvider,
  ThemeProvider,
  jssPreset,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import theme from 'styles/theme';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const ReactPwaTemplateThemeProvider = ({ children }) => {
  const intl = useIntl();
  React.useLayoutEffect(() => {
    document.body.setAttribute('dir', intl.formatMessage({ id: 'direction' }));
  }, [intl]);
  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MuiThemeProvider
          theme={{
            ...theme,
            direction: intl.formatMessage({ id: 'direction' }),
          }}
        >
          {children}
        </MuiThemeProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default ReactPwaTemplateThemeProvider;
