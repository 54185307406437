/* eslint-disable no-console */
import React from 'react';
import {
  Box,
  CircularProgress,
  List,
  IconButton,
  ListItemText,
} from '@material-ui/core';
import { Refresh } from 'styles/assets/icons';
import { FormattedMessage } from 'react-intl';

export const ListIncidents = ({ incidents, refetching, refetch }) => {
  return (
    <Box px={{ xs: 1, md: 5 }}>
      {incidents[0] || refetching ? (
        <>
          <List>
            {incidents.map((incident) => {
              return (
                <ListItemText
                  key={incident.id}
                  primary={`description of the incident = ${incident.incidentDescription}`}
                  secondary={`latitude = ${incident.lat}, longitude = ${incident.lon}`}
                />
              );
            })}
          </List>
          <Box p={2} display="flex" justifyContent="center">
            {!refetching ? (
              <IconButton onClick={() => refetch()}>
                <Refresh />
              </IconButton>
            ) : (
              <CircularProgress size={48} />
            )}
          </Box>
        </>
      ) : (
        <p>
          <FormattedMessage
            id="noIncidents"
            defaultMessage="No incidents on your route"
          />
        </p>
      )}
    </Box>
  );
};
