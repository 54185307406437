import GET_INCIDENTS from '../../queries/getIncidents';

export const handleSubmitIncidentUpdate = (
  cache,
  { data: { submitIncident } }
) => {
  if (submitIncident) {
    try {
      let data = cache.readQuery({
        query: GET_INCIDENTS,
      });

      cache.writeQuery({
        query: GET_INCIDENTS,
        data: {
          ...data,
          getIncidents: {
            ...data.getIncidents,
            result: [...data.getIncidents.result, submitIncident.result[0]],
          },
        },
      });
    } catch (err) {
      return null;
    }
  }
};

// Hold update functions
export const updateHandlerByName = {
  submitIncident: handleSubmitIncidentUpdate,
};
