import { gql } from '@apollo/client';

const SUBMIT_INCIDENT = gql`
  mutation submitIncident($incident: IncidentSubmit!) {
    submitIncident(incident: $incident) {
      result {
        id
        lat
        lon
        incidentDescription
      }
    }
  }
`;

export default SUBMIT_INCIDENT;
