import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

// ACTIONS TYPES
const TRACKED_MUTATIONS_ADD = 'TRACKED_MUTATIONS_ADD';
const TRACKED_MUTATIONS_REMOVE = 'TRACKED_MUTATIONS_REMOVE';

// ACTION CREATORS
export const trackedMutationsAdd = (trackedMutation) => ({
  payload: trackedMutation,
  type: TRACKED_MUTATIONS_ADD,
});

export const trackedMutationsRemove = (id) => ({
  payload: id,
  type: TRACKED_MUTATIONS_REMOVE,
});

// REDUCERS
const processMutation = (state = {}, action) => {
  let newState;
  switch (action.type) {
    case TRACKED_MUTATIONS_ADD:
      newState = {
        ...state,
        [action.payload.id]: action.payload,
      };
      return newState;
    case TRACKED_MUTATIONS_REMOVE:
      newState = {
        ...state,
      };
      delete newState[action.payload];
      return newState;
    default:
      return state;
  }
};

// TRACK IDs OF ALL MUTATIONS THAT ARE IN STORE
const ids = (state = [], action) => {
  let newState;
  switch (action.type) {
    case TRACKED_MUTATIONS_ADD:
      newState = [...state, action.payload.id];
      return newState;
    case TRACKED_MUTATIONS_REMOVE:
      newState = state.filter((id) => id !== action.payload);
      return newState;
    default:
      return state;
  }
};

export default combineReducers({
  byId: processMutation,
  ids,
});

// SELECTORS
const getTrackedMutationsIds = (state) => state.trackedMutations.ids;

const getTrackedMutationsById = (state) => state.trackedMutations.byId;

export const getTrackedMutations = createSelector(
  getTrackedMutationsIds,
  getTrackedMutationsById,
  (pIds, pById) => pIds.map((id) => pById[id])
);
